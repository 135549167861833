import Vue from 'vue';
import deburr from 'lodash/deburr';
import { env } from '@shared/env';
import { getBearerToken } from '@shared/services/Utils';
import APICustomer from '@school/services/API/Customer';
import APIAffiliate from '@school/services/API/Affiliate';
import router from '@school/config/router';

const setApiResourcesURL = (customer) => {
  const { picture } = customer;

  if (picture && !picture.startsWith('http')) {
    customer.picture = `${env.apiResourcesURL}/${picture}`;
  }
};

const createCustomerTrainingsSort = (rootState, rootGetters) => {
  const order = rootState.store.data
    .feature_options
    .appearance
    .customer_trainings_order;
  const trainingsOrder = rootGetters['store/trainingsOrderByUuid'];

  return (a, b) => {
    switch (order) {
      case 'CREATED_AT_ASC':
        return new Date(a.created_at) - new Date(b.created_at);
      case 'CREATED_AT_DESC':
        return new Date(b.created_at) - new Date(a.created_at);
      case 'ALPHANUM_ASC':
        return deburr(a.name.toLowerCase()) > deburr(b.name.toLowerCase())
          ? 1 : -1;
      case 'ALPHANUM_DESC':
        return deburr(b.name.toLowerCase()) > deburr(a.name.toLowerCase())
          ? 1 : -1;
      case 'TRAININGS_ORDER':
        if (!Object.keys(trainingsOrder).includes(a.uuid)) {
          return 0;
        }

        return trainingsOrder[a.uuid] - trainingsOrder[b.uuid];
      default:
        return 0;
    }
  };
};

let readyPromise;

export const state = () => ({
  ready: false,
  affiliateData: null,
});

export const mutations = {
  setReady(state, ready) {
    state.ready = ready;
  },
  update(state, data) {
    setApiResourcesURL(data);
    Vue.auth.user(data);
  },
  setAffiliateData(state, data) {
    const customer = { ...Vue.auth.user() };
    customer.affiliate_data = data;
    state.affiliateData = data;

    Vue.auth.user(customer);
  },
};

export const actions = {
  ready({ dispatch, commit, state }) {
    if (readyPromise) {
      return readyPromise;
    }

    if (state.ready) {
      return true;
    }

    readyPromise = Vue.auth
      .load()
      .then(() => {
        if (Vue.auth.check()) {
          return dispatch('fetch').then(() => Promise.all([dispatch('updateLastLogin')]));
        }

        return null;
      })
      .catch((error) => {
        router.app.$errorHandlers.axios(error);
      })
      .finally(() => {
        commit('setReady', true);
        readyPromise = null;
      });

    return readyPromise;
  },
  fetch({ commit }) {
    return Vue.auth.fetch()
      .then((res) => commit('update', res.data.data));
  },
  getAffiliateData({ commit }) {
    return APICustomer.getAffiliateData()
      .then(({ data }) => commit('setAffiliateData', data));
  },
  joinAffiliates({ commit }) {
    return APICustomer.joinAffiliates()
      .then(({ data }) => commit('setAffiliateData', data));
  },
  askAffiliatePayout({ commit }, payload) {
    return APICustomer.askAffiliatePayout(payload)
      .then(({ data }) => commit('setAffiliateData', data));
  },
  login({ commit, dispatch }, data) {
    return Vue.auth.login({ data })
      .then((res) => {
        commit('update', res.data.data);
        const { redirect } = router.currentRoute.query;
        router.push(redirect || { name: 'dashboard' });
        dispatch('updateLastLogin');
        dispatch('store/fetch', null, { root: true });
        router.app.$gtag.nativeEvent('login');
        return res;
      });
  },
  manualLogin({ commit, dispatch }, data) {
    Vue.auth.token(null, data.bearer, false);
    commit('update', data.customer);
    const { redirect } = router.currentRoute.query;
    router.push(redirect || { name: 'dashboard' });
    dispatch('updateLastLogin');
    dispatch('store/fetch', null, { root: true });
    router.app.$gtag.nativeEvent('login');
    return data;
  },
  logout() {
    Vue.auth.logout();
  },
  register({ commit }, data) {
    return APICustomer.register(data)
      .then((res) => {
        const customer = res.data;

        if (data.autoLogin) {
          commit('update', customer);
        }

        customer.enrollment_status === 'CREATED' && router.app.$gtag.nativeEvent('sign_up');
        return customer;
      });
  },
  enroll({ dispatch, commit }, data) {
    const fn = data.buyable_type === 'TRAINING' ? APICustomer.updateEnrollment : APICustomer.buy;

    return fn(data)
      .then(({ headers, data }) => {
        const body = data.data;

        if (body.requires_action) {
          return body;
        }

        const token = getBearerToken(headers);
        token && Vue.auth.token(null, token, false);
        commit('update', body);
        dispatch('store/fetch', null, { root: true });

        return body;
      });
  },
  registerAffiliate({ dispatch, commit }, data) {
    return APIAffiliate.register(data)
      .then(({ headers, data: { data } }) => {
        const token = getBearerToken(headers);
        token && Vue.auth.token(null, token, false);
        commit('update', data);
        dispatch('store/fetch', null, { root: true });

        return data;
      });
  },
  update({ commit }, data) {
    return APICustomer.update(data)
      .then((data) => commit('update', data.data));
  },
  delete(_, data) {
    return APICustomer.delete(data)
      .then(() => router.push({ name: 'logout' }));
  },
  updateLastLogin() {
    return APICustomer.updateLastLogin();
  },
  askNewPassword(context, email) {
    return APICustomer.askNewPassword(email);
  },
  resetPassword(context, data) {
    return APICustomer.resetPassword(data);
  },
};

export const getters = {
  customer() {
    return Vue.auth.user();
  },
  customerTrainingsAsStudent(state, getters, rootState, rootGetters) {
    return getters.customer && getters.customer
      .customer_trainings
      .filter((one) => one.customer_type === 'STUDENT')
      .sort(createCustomerTrainingsSort(rootState, rootGetters));
  },
  customerTrainingsAsAbandoned(state, getters) {
    return getters.customer && getters.customer
      .customer_trainings
      .filter((one) => one.customer_type === 'ABANDONED_CART');
  },
  customerTrainingsAsStudentAndBlocked(state, getters, rootState, rootGetters) {
    return getters.customer && getters.customer
      .customer_trainings
      .filter((one) => ['STUDENT', 'BLOCKED']
        .includes(one.customer_type))
      .sort(createCustomerTrainingsSort(rootState, rootGetters));
  },
  customerTrainingsAsStudentAndBlockedByUuid(state, getters) {
    const data = getters.customerTrainingsAsStudentAndBlocked;
    return !data ? {} : data.reduce((prev, curr) => {
      prev[curr.uuid] = curr;
      return prev;
    }, {});
  },
  customerPurchases(state, getters) {
    return getters.customer && getters.customer.purchases;
  },
  hasToActivate(state, getters) {
    return getters.customer && !getters.customer.email_verified_at;
  },
};
